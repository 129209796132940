import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { getProfileFullName } from 'Services/Utils'

import { Loader, DataTable, Text } from 'Components/UI'

import { Container } from './styles'

const { HeaderCell, Pagination, Cell } = DataTable

const PromocodeUsers = ({
  promocodeId,
  promotionCode,
  onLoadPromocodeUsers,
}) => {
  const handleLoadPromocodeUsers = useCallback(() => {
    onLoadPromocodeUsers(promocodeId)
  }, [promocodeId, onLoadPromocodeUsers])

  useEffect(() => {
    handleLoadPromocodeUsers()
  }, [handleLoadPromocodeUsers])

  const data = get(promotionCode, 'entity.promotions', [])
  const isLoaded = get(promotionCode, 'isLoaded')

  const columns = useMemo(
    () => [
      {
        id: 'fullname',
        Header: (props) => <HeaderCell {...props}>User</HeaderCell>,
        accessor: (promotion) => (
          <Cell>
            <Text ellipsis>
              {getProfileFullName(
                get(promotion, 'user.profile.firstName'),
                get(promotion, 'user.profile.lastName'),
              )}
            </Text>
          </Cell>
        ),
        sortable: false,
      },
      {
        id: 'email',
        Header: (props) => <HeaderCell {...props}>Email</HeaderCell>,
        accessor: (promotion) => (
          <Cell>
            <Text ellipsis>{get(promotion, 'user.profile.email')}</Text>
          </Cell>
        ),
        sortable: false,
      },
      {
        id: 'activated',
        Header: (props) => <HeaderCell {...props}>Activated</HeaderCell>,
        accessor: (promotion) => {
          const date = DateTime.fromISO(get(promotion, 'createdAt'))

          if (!DateTime.isDateTime(date)) return null

          return (
            <Cell>
              <Text ellipsis>{date.toFormat('LL/dd/yyyy')}</Text>
            </Cell>
          )
        },
        sortable: false,
        maxWidth: 100,
      },
      {
        id: 'expires',
        Header: (props) => <HeaderCell {...props}>Expires</HeaderCell>,
        accessor: (promotion) => {
          const date = DateTime.fromISO(get(promotion, 'expiresAt'))

          if (!DateTime.isDateTime(date)) return null

          return (
            <Cell>
              <Text ellipsis>{date.toFormat('LL/dd/yyyy')}</Text>
            </Cell>
          )
        },
        sortable: false,
        maxWidth: 100,
      },
      {
        id: 'state',
        Header: (props) => <HeaderCell {...props}>Status</HeaderCell>,
        accessor: (promotion) => (
          <Cell>
            <Text ellipsis>{get(promotion, 'state')}</Text>
          </Cell>
        ),
        sortable: false,
        maxWidth: 100,
      },
    ],
    [],
  )

  if (!isLoaded) return <Loader />

  return (
    <Container>
      <DataTable
        PaginationComponent={Pagination}
        className="-highlight"
        columns={columns}
        data={data}
        defaultPageSize={1}
        filterable={false}
        manual
        pagination={false}
        resizable={false}
        style={{
          width: '100%',
        }}
      />
    </Container>
  )
}

PromocodeUsers.defaultProps = {
  promocodeId: null,
  promotionCode: null,
  onLoadPromocodeUsers: noop,
}

PromocodeUsers.propTypes = {
  promocodeId: PropTypes.string,
  promotionCode: PropTypes.object,
  onLoadPromocodeUsers: PropTypes.func,
}

export default PromocodeUsers
