import {
  CREATE_ORGANIZATION_ICON,
  DELETE_ORGANIZATION_ICON,
} from 'Store/Actions/admin/organizations'
import { createReducer } from 'Store/utils'

const initialState = {}

const handlers = {
  [CREATE_ORGANIZATION_ICON.SUCCESS]: (state, { payload }) => {
    const { organizationId } = payload
    const iconId = Object.values(payload?.data?.organizationsIcons ?? {})[0]?.id

    return {
      ...state,
      [organizationId]: {
        ...state[organizationId],
        relationships: {
          ...state[organizationId].relationships,
          icons: {
            data: [
              ...(state?.[organizationId]?.relationships?.icons?.data ?? []),
              {
                id: iconId,
                type: 'organizationsIcons',
              },
            ],
          },
        },
      },
    }
  },

  [DELETE_ORGANIZATION_ICON.SUCCESS]: (state, { payload }) => {
    const { organizationId, iconId } = payload

    const newIcons =
      state?.[organizationId]?.relationships?.icons?.data?.filter(
        (icon) => icon.id !== iconId,
      ) ?? []

    return {
      ...state,
      [organizationId]: {
        ...state[organizationId],
        relationships: {
          ...state[organizationId].relationships,
          icons: {
            data: newIcons,
          },
        },
      },
    }
  },
}

export default createReducer(initialState, handlers)
