import { combineReducers } from 'redux'

import users from './users'
import waitingRooms from './waitingRooms'
import statistics from './statistics'
import headquarters from './headquarters'
import organizations from './organizations'
import promotionCodes from './promocodes'
import ocards from './ocards'
import stickers from './stickers'
import organizationsIcons from './organizationsIcons'

export default combineReducers({
  headquarters,
  ocards,
  organizations,
  organizationsIcons,
  promotionCodes,
  statistics,
  stickers,
  users,
  waitingRooms,
})
