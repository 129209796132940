import { logoGlyph, logoOmniGlyph } from 'Assets/Svg'

import { APP_TYPES, MOBILE_TYPES } from './types'

export const PROMOTION_TABLE_ACTIONS = {
  SHOW: 'show',
  EDIT: 'edit',
  DELETE: 'delete',
}

export const PROMOTION_TABLE_ACTION_OPTIONS = [
  {
    label: 'Show',
    value: PROMOTION_TABLE_ACTIONS.SHOW,
  },
  {
    label: 'Edit',
    value: PROMOTION_TABLE_ACTIONS.EDIT,
  },
  {
    label: 'Delete',
    value: PROMOTION_TABLE_ACTIONS.DELETE,
  },
]

export const WAITING_ROOM_KIND = {
  BABY: 'baby',
  HEALTH: 'health',
  KIDS_HEALTH: 'kids_health',
  UNIVERSAL: 'universal',
}

export const ROOM_STATE = {
  active: 'active',
  deactivated: 'deactivated',
}

export const PROMOCODE_STATE = {
  active: 'active',
  deactivated: 'deactivated',
}

export const WAITING_ROOM_KIND_OPTIONS = [
  { label: 'Baby', value: WAITING_ROOM_KIND.BABY },
  { label: 'Health', value: WAITING_ROOM_KIND.HEALTH },
  { label: 'Kids health', value: WAITING_ROOM_KIND.KIDS_HEALTH },
  { label: 'Universal', value: WAITING_ROOM_KIND.UNIVERSAL },
]

export const PROMOCODE_KIND = {
  DAILY: 'daily',
  ABSOLUTE: 'absolute',
}

export const PROMOCODE_KIND_OPTIONS = [
  { label: 'Duration Days', value: PROMOCODE_KIND.DAILY },
  { label: 'Absolute End', value: PROMOCODE_KIND.ABSOLUTE },
]

export const PROMO_CODE_VALID_NUMBERS = '2346789'
export const PROMO_CODE_VALID_LETTERS = 'BCDFGHJKMPQRTVWXY'

export const APPS = {
  [APP_TYPES.baby]: {
    LOGO: logoGlyph,
    TITLE: 'OhanaLink Baby',
    PRODUCTION: {
      NAME: 'olb',
      HOST: 'baby.ohanalink.tech',
      DOWNLOAD_LINK: {
        [MOBILE_TYPES.iOS]:
          'https://itunes.apple.com/us/app/ohanalink-baby/id1507352284',
        [MOBILE_TYPES.android]:
          'https://play.google.com/store/apps/details?id=tech.ohanalink.baby',
      },
    },
    STAGING: {
      NAME: 'olbstaging',
      HOST: 'staging.baby.ohanalink.tech',
      DOWNLOAD_LINK: {
        [MOBILE_TYPES.iOS]:
          'https://apps.apple.com/us/app/ohanalink-baby-staging/id1507350883',
        [MOBILE_TYPES.android]:
          'https://play.google.com/store/apps/details?id=tech.ohanalink.baby.staging',
      },
    },
  },
  [APP_TYPES.omni]: {
    LOGO: logoOmniGlyph,
    TITLE: 'OhanaLink Omni',
    PRODUCTION: {
      NAME: 'olh',
      HOST: 'omni.ohanalink.tech',
      DOWNLOAD_LINK: {
        [MOBILE_TYPES.iOS]:
          'https://apps.apple.com/us/app/ohanalink/id1533807658',
        [MOBILE_TYPES.android]:
          'https://play.google.com/store/apps/details?id=tech.ohanalink.omni',
      },
    },
    STAGING: {
      NAME: 'olhstaging',
      HOST: 'staging.omni.ohanalink.tech',
      DOWNLOAD_LINK: {
        [MOBILE_TYPES.iOS]:
          'https://apps.apple.com/us/app/ohanalink-staging/id1533809448',
        [MOBILE_TYPES.android]:
          'https://play.google.com/store/apps/details?id=tech.ohanalink.omni.staging',
      },
    },
  },
}
