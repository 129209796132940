import {
  createFields,
  createReducerHandlers,
  createDeleteHandler,
} from 'rapidux'

import { createLoadHandler } from 'rapidux/lib/entityHandlers'

import { createReducer } from 'Store/utils'

import { LOG_OUT } from 'Store/Actions/viewer'

import {
  CREATE_HEADQUARTER,
  LOAD_HEADQUARTERS,
  LOAD_HEADQUARTER,
  DELETE_HEADQUARTER,
  CLEAR_HEADQUARTERS,
} from 'Store/Actions/admin/headquarters'

const initialHeadquartersFields = createFields('headquarters')
const initialHeadquarterFields = createFields(
  'headquarters',
  'headquarter',
  true,
)
const initialPage = {}
const initialDefaultSize = 10

const initialState = {
  ...initialHeadquartersFields,
  ...initialHeadquarterFields,
  paged: initialPage,
  defaultSize: initialDefaultSize,
}

const handlers = {
  ...createReducerHandlers('headquarters', LOAD_HEADQUARTERS, {
    withReplace: true,
  }),

  ...createReducerHandlers('headquarters', LOAD_HEADQUARTER, {
    withReplace: true,
    mapToKey: 'headquarter',
    singular: true,
  }),

  ...createReducerHandlers('headquarters', CREATE_HEADQUARTER, {
    mapToKey: 'headquarter',
    singular: true,
  }),

  [CREATE_HEADQUARTER.SUCCESS]: createLoadHandler('headquarters', {
    withLoading: false,
  }),

  [DELETE_HEADQUARTER.SUCCESS]: createDeleteHandler('headquarters', {
    withLoading: false,
  }),

  [CLEAR_HEADQUARTERS]: (state) =>
    state.merge({ ...initialHeadquartersFields }),

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
