import { createAsyncAction } from 'Store/utils'

import apiCall from 'Services/Api'
import {
  getCheckedActiveOCards,
  getCheckedDeactivatedOCards,
  getOCardsKind,
} from 'Store/Selectors/admin/ocards'

export const SET_OCARDS_KIND = 'admin/ocards/SET_OCARDS_KIND'
export const SET_OCARDS_PAGE = 'admin/ocards/SET_OCARDS_PAGE'
export const SET_OCARDS_SIZE = 'admin/ocards/SET_OCARDS_SIZE'
export const CHECK_OCARD = 'admin/ocards/CHECK_OCARD'
export const LOAD_OCARDS = createAsyncAction('admin/ocards/LOAD_OCARDS')
export const CREATE_OCARD = createAsyncAction('admin/ocards/CREATE_OCARD')
export const DEACTIVATE_OCARDS = createAsyncAction(
  'admin/ocards/DEACTIVATE_OCARDS',
)
export const ACTIVATE_OCARDS = createAsyncAction('admin/ocards/ACTIVATE_OCARDS')

const ocardsIncludes = ['postcard']

export const setOCardsKind = (kind) => ({
  type: SET_OCARDS_KIND,
  payload: kind,
})

export const setOCardsPage = (page) => ({
  type: SET_OCARDS_PAGE,
  payload: page,
})

export const setOCardsSize = (size) => ({
  type: SET_OCARDS_SIZE,
  payload: size,
})

export const checkOCard = ({ id, state }) => ({
  type: CHECK_OCARD,
  payload: { id, isActive: state === 'active' },
})

export const loadOCards = ({ number = 1, size = 10 } = {}) => (
  dispatch,
  getState,
) => {
  const waitingRoomKind = getOCardsKind(getState())

  return dispatch(
    apiCall({
      endpoint: '/admin/ocards/',
      types: LOAD_OCARDS,
      query: {
        include: ocardsIncludes.join(),
        page: {
          number,
          size,
        },
        filter: {
          waitingRoomKind: {
            eq: waitingRoomKind,
          },
        },
      },
      paged: true,
      payload: {
        paged: {
          number,
          size,
        },
      },
    }),
  )
}

export const createOCard = ({ postcardId }) => (dispatch, getState) => {
  const waitingRoomKind = getOCardsKind(getState())

  return dispatch(
    apiCall({
      endpoint: '/admin/ocards',
      method: 'POST',
      types: CREATE_OCARD,
      query: {
        include: ocardsIncludes.join(),
        data: {
          type: 'ocards',
          attributes: {
            waitingRoomKind,
          },
          relationships: {
            postcard: {
              data: {
                type: 'postcards',
                id: postcardId,
              },
            },
          },
        },
      },
    }),
  )
}

export const deactivateOCards = () => (dispatch, getState) => {
  const checked = getCheckedActiveOCards(getState())

  return dispatch(
    apiCall({
      endpoint: '/admin/ocards/deactivation',
      method: 'POST',
      types: DEACTIVATE_OCARDS,
      query: {
        data: checked.map((item) => ({
          id: item.toString(),
          type: 'ocards',
        })),
      },
    }),
  )
}

export const activateOCards = () => (dispatch, getState) => {
  const checked = getCheckedDeactivatedOCards(getState())

  return dispatch(
    apiCall({
      endpoint: '/admin/ocards/activation',
      method: 'POST',
      types: ACTIVATE_OCARDS,
      query: {
        data: checked.map((item) => ({
          id: item.toString(),
          type: 'ocards',
        })),
      },
    }),
  )
}
