import pickBy from 'lodash/pickBy'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import negate from 'lodash/negate'

export const transformToRequest = ({
  logo,
  headquarter,
  image,
  ...attributes
}) => {
  const headquarterId = get(headquarter, 'id') || get(headquarter, 'value')

  return {
    attributes: pickBy({ ...attributes }, negate(isNil)),
    relationships: {
      ...(logo
        ? {
            logo: {
              data: {
                type: 'banners',
                id: logo,
              },
            },
          }
        : {
            logo: {
              data: null,
            },
          }),
      ...(headquarter
        ? {
            headquarter: {
              data: {
                type: 'headquarters',
                id: headquarterId,
              },
            },
          }
        : {}),
      ...(image
        ? {
            image: {
              data: {
                type: 'images',
                id: image,
              },
            },
          }
        : {}),
    },
  }
}

export default {}
