import styled from 'styled-components'
import { Box } from 'rebass'

import { Button } from 'Components/UI'

export const ImageWrapper = styled(Box)`
  position: relative;
  text-align: center;
`

export const Image = styled(Box).attrs({
  as: 'img',
})`
  width: 48px;
  height: 48px;
`

export const RemoveButton = styled(Box)`
  position: absolute;
  left: calc(50% + 24px);
  top: 0;
  cursor: pointer;
`

export const Input = styled(Box).attrs({
  as: 'input',
  type: 'file',
})`
  display: none;
`

export const ButtonUpload = styled(Button).attrs({
  as: 'label',
})`
  display: flex;
  align-items: center;
  width: max-content;
  margin: auto;
`
