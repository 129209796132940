import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Form } from 'react-final-form'
import { Flex } from 'rebass'

import noop from 'lodash/noop'
import get from 'lodash/get'

import { getFilterForm } from 'Store/Selectors/ui'

import { Forms } from 'Components/Blocks'

import FormStateToRedux from './FormStateToRedux'
import { FormWrap, FieldWrap } from './styles'

const type = 'organizations'

const OrganizationsFilterForm = ({ form }) => {
  const initialValues = {
    ...form.values,
    headquarterName: get(form, 'headquarterName', ''),
  }
  return (
    <Form initialValues={initialValues} onSubmit={noop}>
      {({ handleSubmit }) => (
        <FormWrap onSubmit={handleSubmit}>
          <FormStateToRedux type={type} />
          <Flex width={1}>
            {/*
            // TODO: temporary disable this field
            <FieldWrap mr={4}>
              <Forms.Input
                name="headquarterName"
                placeholder="Search by Parent Organization Name"
              />
            </FieldWrap>
            */}
            <FieldWrap>
              <Forms.Input
                name="organizationName"
                placeholder="Search by Organization Name"
              />
            </FieldWrap>
          </Flex>
        </FormWrap>
      )}
    </Form>
  )
}

OrganizationsFilterForm.propTypes = { form: PropTypes.object.isRequired }

export default connect(
  createStructuredSelector({
    form: (state, props) => getFilterForm(type)(state, props),
  }),
)(OrganizationsFilterForm)
