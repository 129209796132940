import React from 'react'
import PropTypes from 'prop-types'

import {
  menuDashboardGlyph,
  menuUsersGlyph,
  menuWaitingRoomsGlyph,
  menuPromotionsGlyph,
  menuOCardsGlyph,
  menuPromocodesGlyph,
} from 'Assets/Svg/Menu'

import { APPS } from 'Constants/ids'
import { HUMAN_USER_TYPES } from 'Constants/types'
import { ROOT_PATH, ADMIN_ROOT, ADMIN_PATHS } from 'Constants/paths'

import { Icon } from 'Components/UI'
import { StickerIcon } from 'Components/UI/Icons'

import { getAppType } from 'Services/Utils'

import { Container, Bar, Menu, StyledNav, Label, Top, Bottom } from './styles'

function Sidebar({ viewer, shrinked }) {
  const appType = getAppType()

  const logo = APPS[appType].LOGO

  return (
    <Container shrinked={shrinked}>
      <Bar shrinked={shrinked}>
        <Menu>
          <Top>
            <StyledNav
              className="logo"
              exact
              href={ROOT_PATH}
              logo="true"
              to={ADMIN_ROOT}
            >
              <Icon glyph={logo} size={40} />
              {HUMAN_USER_TYPES[viewer.type]} Panel
            </StyledNav>
            <StyledNav
              exact
              href={ADMIN_PATHS.DASHBOARD}
              to={ADMIN_PATHS.DASHBOARD}
            >
              <Icon glyph={menuDashboardGlyph} size={24} />
              <Label>Dashboard</Label>
            </StyledNav>
            <StyledNav href={ADMIN_PATHS.USERS} to={ADMIN_PATHS.USERS}>
              <Icon glyph={menuUsersGlyph} size={24} />
              <Label>Users</Label>
            </StyledNav>

            <StyledNav
              href={ADMIN_PATHS.WAITING_ROOMS}
              to={ADMIN_PATHS.WAITING_ROOMS}
            >
              <Icon glyph={menuWaitingRoomsGlyph} size={24} />
              <Label>Waiting Rooms</Label>
            </StyledNav>
            <StyledNav
              href={ADMIN_PATHS.PROMOTIONS}
              to={ADMIN_PATHS.PROMOTIONS}
            >
              <Icon glyph={menuPromotionsGlyph} size={24} />
              <Label>Promotions</Label>
            </StyledNav>
            <StyledNav href={ADMIN_PATHS.CODES} to={ADMIN_PATHS.CODES}>
              <Icon glyph={menuPromocodesGlyph} size={24} />
              <Label>Codes</Label>
            </StyledNav>
            <StyledNav href={ADMIN_PATHS.OCARDS} to={ADMIN_PATHS.OCARDS}>
              <Icon glyph={menuOCardsGlyph} size={24} />
              <Label>O-Cards</Label>
            </StyledNav>
            <StyledNav href={ADMIN_PATHS.STICKERS} to={ADMIN_PATHS.STICKERS}>
              <StickerIcon />
              <Label>Stickers</Label>
            </StyledNav>
          </Top>
          <Bottom />
        </Menu>
      </Bar>
    </Container>
  )
}

Sidebar.propTypes = {
  shrinked: PropTypes.bool.isRequired,
  viewer: PropTypes.object.isRequired,
}

export default Sidebar
