import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'rebass'

import map from 'lodash/map'
import noop from 'lodash/noop'

import Icon from 'Components/UI/Icon'
import { Container, Label, Tab, TabList, TabPanel, TabsWrapper } from './styles'

const Tabs = ({ defaultIndex, tabs, onSelect }) => {
  const createLabel = (icon, label) => (
    <Flex alignItems="center" justifyContent="flex-start" width={1}>
      {icon && <Icon glyph={icon} mr={2} size={16} />}
      <Label>{label}</Label>
    </Flex>
  )

  const tabsData = map(tabs, (tab, index) => ({
    key: index,
    label: createLabel(tab.icon, tab.label),
    value: tab.value,
  }))

  return (
    <TabsWrapper defaultIndex={defaultIndex} onSelect={onSelect}>
      <TabList>
        {map(tabsData, (tab) => (
          <Tab key={tab.key}>{tab.label}</Tab>
        ))}
      </TabList>
      {map(tabsData, (tab) => (
        <TabPanel key={tab.key}>
          <Container>{tab.value}</Container>
        </TabPanel>
      ))}
    </TabsWrapper>
  )
}

Tabs.defaultProps = {
  defaultIndex: 0,
  tabs: [],
  onSelect: noop,
}

Tabs.propTypes = {
  defaultIndex: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      label: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
    }),
  ),
  onSelect: PropTypes.func,
}

export default Tabs
