import styled from 'styled-components'

import Icon from 'Components/UI/Icon'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  font-size: 12px;
  color: #b5b5b5;
`

export const StyledIcon = styled(Icon)`
  width: 80px;
  height: 50px;
`
