import { createFields, createLoadHandler, createReducerHandlers } from 'rapidux'

import { createReducer } from 'Store/utils'
import { LOG_OUT } from 'Store/Actions/viewer'
import {
  CREATE_OCARD,
  LOAD_OCARDS,
  SET_OCARDS_KIND,
  SET_OCARDS_PAGE,
  SET_OCARDS_SIZE,
  CHECK_OCARD,
  DEACTIVATE_OCARDS,
  ACTIVATE_OCARDS,
} from 'Store/Actions/admin/ocards'

const initialState = {
  ...createFields('ocards'),
  kind: 'baby',
  paged: {},
  page: 1,
  size: 10,
  checkedActive: [],
  checkedDeactivated: [],
}

const handlers = {
  ...createReducerHandlers('ocards', LOAD_OCARDS, {
    withReplace: true,
    withLoading: true,
  }),

  [CREATE_OCARD.SUCCESS]: createLoadHandler('ocards', {
    withLoading: false,
  }),

  [SET_OCARDS_KIND]: (state, { payload }) =>
    state.merge({
      kind: payload,
      checkedActive: [],
      checkedDeactivated: [],
    }),
  [SET_OCARDS_PAGE]: (state, { payload }) =>
    state.merge({
      page: payload,
      checkedActive: [],
      checkedDeactivated: [],
    }),
  [SET_OCARDS_SIZE]: (state, { payload }) =>
    state.merge({
      size: payload,
      checkedActive: [],
      checkedDeactivated: [],
    }),
  [CHECK_OCARD]: (state, { payload }) => {
    const { id, isActive } = payload
    const path = isActive ? 'checkedActive' : 'checkedDeactivated'
    const checked = state[path]

    const newChecked = checked.includes(id)
      ? checked.filter((item) => item !== id)
      : [...checked, id]

    return state.merge({
      [path]: newChecked,
    })
  },
  [DEACTIVATE_OCARDS.SUCCESS]: (state) => state.merge({ checkedActive: [] }),
  [ACTIVATE_OCARDS.SUCCESS]: (state) => state.merge({ checkedDeactivated: [] }),

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
