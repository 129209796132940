import styled from 'styled-components'

import { BiSticker } from 'react-icons/bi'

import CommonIconStyles from './CommonIconStyles'

const StickerIcon = styled(BiSticker)`
  ${CommonIconStyles};
`

export default StickerIcon
