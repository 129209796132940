import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { uploadFile } from 'Store/Actions/uploading'
import { closeGlyph } from 'Assets/Svg'
import { Icon } from 'Components/UI'
import { toast } from 'react-toastify'
import {
  ButtonUpload,
  Input,
  Image,
  ImageWrapper,
  RemoveButton,
} from './styles'

export const IconUploader = ({ disabled, id, url, onChange }) => {
  const dispatch = useDispatch()

  const handleChange = useCallback(
    async (e) => {
      const file = e.target.files && e.target.files[0]

      if (!file) return

      const { ok, payload } = await dispatch(uploadFile('/admin/icons', file))

      if (ok) {
        const icon = Object.values(payload?.data?.icons ?? {})[0]
        const iconUrl = icon?.attributes?.content?.url
        onChange({ id: icon.id, url: iconUrl })
      } else {
        toast.error('Failed to upload icon')
      }
    },
    [dispatch, onChange],
  )

  const handleRemove = useCallback(() => {
    onChange(null)
  }, [onChange])

  if (url)
    return (
      <ImageWrapper>
        {!disabled && (
          <RemoveButton onClick={handleRemove}>
            <Icon fill="red" glyph={closeGlyph} size={10} />
          </RemoveButton>
        )}
        <Image src={url} />
      </ImageWrapper>
    )

  return (
    <ButtonUpload htmlFor={id}>
      Upload Icon
      <Input accept="image/png, image/jpeg" id={id} onChange={handleChange} />
    </ButtonUpload>
  )
}

IconUploader.defaultProps = {
  disabled: false,
  url: null,
}

IconUploader.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.number.isRequired,
  url: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
