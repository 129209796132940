import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Input, Label } from './styles'

const Switch = ({ readonly, checked, onChange, disabled }) => (
  <Wrapper>
    <Input
      checked={checked}
      disabled={disabled}
      readonly={readonly}
      onChange={onChange}
    />
    <Label />
  </Wrapper>
)

Switch.defaultProps = {
  readonly: false,
  checked: false,
  disabled: false,
  onChange: () => null,
}
Switch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Switch
