import { ROOT_PATH } from 'Constants/paths'

import * as PublicPages from 'Containers/Pages/Public'

const routes = () => [
  /* Public */
  {
    path: ROOT_PATH,
    exact: true,
    component: PublicPages.Landing,
  },
  { component: PublicPages.MobileRedirector },
]

export default routes
