import {
  CREATE_ORGANIZATION_ICON,
  UPDATE_ORGANIZATION_ICON,
} from 'Store/Actions/admin/organizations'
import { createReducer } from 'Store/utils'

const initialState = {}

const handlers = {
  [CREATE_ORGANIZATION_ICON.SUCCESS]: (state, { payload }) => {
    const { imageId } = payload
    const iconId = Object.values(payload?.data?.organizationsIcons ?? {})[0]?.id

    return {
      ...state,
      [iconId]: {
        ...state[iconId],
        relationships: {
          ...state[iconId].relationships,
          image: {
            data: {
              id: imageId,
              type: 'icons',
            },
          },
        },
      },
    }
  },
  [UPDATE_ORGANIZATION_ICON.SUCCESS]: (state, { payload }) => {
    const { imageId } = payload
    const iconId = Object.values(payload?.data?.organizationsIcons ?? {})[0]?.id

    return {
      ...state,
      [iconId]: {
        ...state[iconId],
        relationships: {
          ...state[iconId].relationships,
          image: {
            data: {
              id: imageId,
              type: 'icons',
            },
          },
        },
      },
    }
  },
}

export default createReducer(initialState, handlers)
