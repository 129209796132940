import { css } from 'styled-components'
import theme from '@styled-system/theme-get'

const hoverCss = ({ hover }) =>
  hover &&
  css`
    &:hover {
      stroke: ${theme('colors.primary')};
    }
  `

const CommonIconStyles = css`
  stroke: ${theme('colors.secondary')};
  width: ${(props) => (props.size ? props.size : 24)}px;
  height: ${(props) => (props.size ? props.size : 24)}px;

  cursor: pointer;

  ${hoverCss};
`

export default CommonIconStyles
