import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { createStructuredSelector } from 'reselect'
import { compose } from 'recompose'

import { withAppContext } from 'Services/Context'

import { loadPromocodeUsers } from 'Store/Actions/admin/promocodes'

import { getPromocode } from 'Store/Selectors/admin/promocodes'

import PromocodeUsers from './PromocodeUsers'

export default compose(
  withRouter,
  withAppContext,
  connect(
    createStructuredSelector({
      promotionCode: getPromocode,
    }),
    {
      onLoadPromocodeUsers: loadPromocodeUsers,
    },
  ),
)(PromocodeUsers)
