import { createSelector } from 'reselect'
import { getEntities } from 'rapidux'

import { getData } from 'Store/Selectors/data'

export const getState = (state) => state.admin.stickers

export const getStickers = getEntities(getState, getData, {
  type: 'stickers',
})

export const getStickersKind = createSelector(getState, (state) => state.kind)

export const getStickersPaged = createSelector(getState, (state) => state.paged)

export const getStickersPage = createSelector(getState, (state) => state.page)

export const getStickersSize = createSelector(getState, (state) => state.size)

export const getCheckedActiveStickers = createSelector(
  getState,
  (state) => state.checkedActive,
)

export const getCheckedDeactivatedStickers = createSelector(
  getState,
  (state) => state.checkedDeactivated,
)
