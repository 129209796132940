import React from 'react'
import PropTypes from 'prop-types'
import { useForm, useFormState } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import get from 'lodash/get'

const WhenFieldChanges = ({ field, set }) => {
  const { values } = useFormState()
  const form = useForm()

  return (
    <OnChange name={field}>
      {() => form.change(set, get(values, field))}
    </OnChange>
  )
}

WhenFieldChanges.defaultProps = {}

WhenFieldChanges.propTypes = {
  field: PropTypes.string.isRequired,
  set: PropTypes.string.isRequired,
}

export default WhenFieldChanges
