import { mergers } from 'rapidux'

import mergeWith from 'lodash/mergeWith'
import reduce from 'lodash/reduce'
import set from 'lodash/set'

import organizations from './organizations'
import organizationsIcons from './organizationsIcons'

const reducers = {
  organizations,
  organizationsIcons,
}

export default (state, action) =>
  mergeWith(
    {},
    reduce(
      reducers,
      (acc, reducer, key) => set(acc, key, reducer(state[key], action)),
      state,
    ),
    mergers.latestArrayMerger,
  )
