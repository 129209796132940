import { createSelector } from 'reselect'
import { getEntities } from 'rapidux'

import { getData } from 'Store/Selectors/data'

export const getState = (state) => state.admin.ocards

export const getOCards = getEntities(getState, getData, {
  type: 'ocards',
})

export const getOCardsKind = createSelector(getState, (state) => state.kind)

export const getOCardsPaged = createSelector(getState, (state) => state.paged)

export const getOCardsPage = createSelector(getState, (state) => state.page)

export const getOCardsSize = createSelector(getState, (state) => state.size)

export const getCheckedActiveOCards = createSelector(
  getState,
  (state) => state.checkedActive,
)

export const getCheckedDeactivatedOCards = createSelector(
  getState,
  (state) => state.checkedDeactivated,
)
