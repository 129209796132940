import {
  createDeleteHandler,
  createFields,
  createLoadHandler,
  createReducerHandlers,
} from 'rapidux'
import {
  CREATE_ORGANIZATION_ICON,
  DELETE_ORGANIZATION_ICON,
  LOAD_ORGANIZATIONS_ICONS,
} from 'Store/Actions/admin/organizations'
import { LOG_OUT } from 'Store/Actions/viewer'
import { createReducer } from 'Store/utils'

const initialState = {
  ...createFields('organizationsIcons'),
  paged: {},
}

const handlers = {
  ...createReducerHandlers('organizationsIcons', LOAD_ORGANIZATIONS_ICONS, {
    withReplace: true,
  }),

  [CREATE_ORGANIZATION_ICON.SUCCESS]: createLoadHandler('organizationsIcons', {
    withLoading: false,
  }),

  [DELETE_ORGANIZATION_ICON.SUCCESS]: createDeleteHandler('organizationsIcons'),

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
