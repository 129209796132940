import { createFields, createLoadHandler, createReducerHandlers } from 'rapidux'

import { createReducer } from 'Store/utils'
import { LOG_OUT } from 'Store/Actions/viewer'
import {
  CREATE_STICKER,
  LOAD_STICKERS,
  SET_STICKERS_KIND,
  SET_STICKERS_PAGE,
  SET_STICKERS_SIZE,
  CHECK_STICKER,
  DEACTIVATE_STICKERS,
  ACTIVATE_STICKERS,
} from 'Store/Actions/admin/stickers'

const initialState = {
  ...createFields('stickers'),
  kind: 'kids_health',
  paged: {},
  page: 1,
  size: 10,
  checkedActive: [],
  checkedDeactivated: [],
}

const handlers = {
  ...createReducerHandlers('stickers', LOAD_STICKERS, {
    withReplace: true,
    withLoading: true,
  }),

  [CREATE_STICKER.SUCCESS]: createLoadHandler('stickers', {
    withLoading: false,
  }),

  [SET_STICKERS_KIND]: (state, { payload }) =>
    state.merge({
      kind: payload,
      checkedActive: [],
      checkedDeactivated: [],
    }),
  [SET_STICKERS_PAGE]: (state, { payload }) =>
    state.merge({
      page: payload,
      checkedActive: [],
      checkedDeactivated: [],
    }),
  [SET_STICKERS_SIZE]: (state, { payload }) =>
    state.merge({
      size: payload,
      checkedActive: [],
      checkedDeactivated: [],
    }),
  [CHECK_STICKER]: (state, { payload }) => {
    const { id, isActive } = payload
    const path = isActive ? 'checkedActive' : 'checkedDeactivated'
    const checked = state[path]

    const newChecked = checked.includes(id)
      ? checked.filter((item) => item !== id)
      : [...checked, id]

    return state.merge({
      [path]: newChecked,
    })
  },
  [DEACTIVATE_STICKERS.SUCCESS]: (state) => state.merge({ checkedActive: [] }),
  [ACTIVATE_STICKERS.SUCCESS]: (state) =>
    state.merge({ checkedDeactivated: [] }),

  [LOG_OUT]: (state) => state.merge(initialState),
}

export default createReducer(initialState, handlers)
