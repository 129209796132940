import styled from 'styled-components'

import { FiEdit3 } from 'react-icons/fi'

import CommonIconStyles from './CommonIconStyles'

const EditIcon = styled(FiEdit3)`
  ${CommonIconStyles};
`

export default EditIcon
