import React from 'react'

import { emptyGlyph } from 'Assets/Svg'
import { Wrap, StyledIcon } from './styles'

export const Empty = () => {
  return (
    <Wrap>
      <StyledIcon glyph={emptyGlyph} />
      No Data
    </Wrap>
  )
}
