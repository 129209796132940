import merge from 'lodash/merge'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import omit from 'lodash/omit'

import { createAsyncAction } from 'Store/utils'

import apiCall from 'Services/Api'
import { transformToRequest } from 'Services/Entities/Organization'
import { getOrganizationId } from 'Store/Selectors/admin/organizations'

export const LOAD_ORGANIZATIONS = createAsyncAction('admin/organizations/LOAD')
export const LOAD_ORGANIZATION = createAsyncAction(
  'admin/organizations/LOAD_ONE',
)
export const UPDATE_ORGANIZATION = createAsyncAction(
  'admin/organizations/UPDATE',
)
export const DELETE_ORGANIZATION = createAsyncAction(
  'admin/organizations/DELETE',
)
export const CREATE_ORGANIZATION = createAsyncAction(
  'admin/organizations/CREATE',
)
export const LOAD_ORGANIZATIONS_ICONS = createAsyncAction(
  'admin/organizations/LOAD_ICONS',
)
export const CREATE_ORGANIZATION_ICON = createAsyncAction(
  'admin/organizations/CREATE_ICON',
)
export const UPDATE_ORGANIZATION_ICON = createAsyncAction(
  'admin/organizations/UPDATE_ICON',
)
export const DELETE_ORGANIZATION_ICON = createAsyncAction(
  'admin/organizations/DELETE_ICON',
)

const organizationsIncludes = [
  'logo',
  'headquarter',
  'headquarterLogo',
  'icons',
  'icons.image',
  'icons.organization',
]

export const loadOrganizations = ({
  paged = true,
  number = 1,
  size = 10,
  sort,
  filters,
} = {}) => (dispatch, getState) => {
  const params = {
    endpoint: '/admin/organizations/',
    types: LOAD_ORGANIZATIONS,
    query: {
      include: organizationsIncludes.join(),
      filter: {
        state: {
          eq: 'active',
        },
      },
    },
    paged: true,
  }
  if (paged) {
    merge(params, {
      paged,
      query: pickBy(
        {
          include: organizationsIncludes.join(),
          page: {
            number,
            size,
          },
          filter: filters,
          sort,
        },
        identity,
      ),
      payload: {
        paged: {
          number,
          size,
        },
      },
    })
  }

  return apiCall(params)(dispatch, getState)
}

export const loadOrganization = (organizationId) =>
  apiCall({
    endpoint: `/admin/organizations/${organizationId}`,
    types: LOAD_ORGANIZATION,
    query: {
      include: organizationsIncludes.join(),
    },
  })

export const updateOrganization = (values) => (dispatch, getState) => {
  const organizationId = getOrganizationId(getState()) || values.id
  return dispatch(
    apiCall({
      method: 'PATCH',
      endpoint: `/admin/organizations/${organizationId}`,
      types: UPDATE_ORGANIZATION,
      query: {
        include: 'logo',
        data: {
          type: 'organization',
          ...transformToRequest(values),
        },
      },
    }),
  )
}

export const createOrganization = (values) => (dispatch) => {
  let params = transformToRequest(values)
  if (!values?.logo) {
    params = omit(params, 'relationships.logo')
  }
  const query = {
    include: organizationsIncludes.join(),
    data: {
      type: 'organizations',
      ...params,
    },
  }

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: '/admin/organizations/',
      types: CREATE_ORGANIZATION,
      query,
    }),
  )
}

export const deleteOrganization = (values) => (dispatch, getState) => {
  const organizationId = getOrganizationId(getState()) || values.id

  return dispatch(
    apiCall({
      method: 'DELETE',
      endpoint: `/admin/organizations/${organizationId}`,
      types: DELETE_ORGANIZATION,
      query: {
        include: organizationsIncludes.join(),
      },
      payload: {
        deletedId: organizationId,
      },
    }),
  )
}

export const loadOrganizationsIcons = (organizationId) =>
  apiCall({
    endpoint: `/admin/organizations/${organizationId}/organizations_icons`,
    types: LOAD_ORGANIZATIONS_ICONS,
    query: {
      sort: 'createdAt',
      page: {
        number: 1,
        size: Number.MAX_SAFE_INTEGER,
      },
      include: 'image',
    },
    paged: true,
  })

export const createOrganizationIcon = (values) => (dispatch, getState) => {
  const organizationId = getOrganizationId(getState())

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: `/admin/organizations/${organizationId}/organizations_icons`,
      types: CREATE_ORGANIZATION_ICON,
      query: {
        include: organizationsIncludes.join(),
        data: {
          type: 'organizationsIcon',
          ...transformToRequest(values),
        },
      },
      payload: {
        organizationId,
        imageId: values.image,
      },
    }),
  )
}

export const updateOrganizationIcon = ({ id, ...values }) => (
  dispatch,
  getState,
) => {
  const organizationId = getOrganizationId(getState())

  return dispatch(
    apiCall({
      method: 'PATCH',
      endpoint: `/admin/organizations_icons/${id}`,
      types: UPDATE_ORGANIZATION_ICON,
      query: {
        include: organizationsIncludes.join(),
        data: {
          type: 'organizationsIcon',
          ...transformToRequest(values),
        },
      },
      payload: {
        organizationId,
        imageId: values.image,
      },
    }),
  )
}

export const deleteOrganizationIcon = (id) => (dispatch, getState) => {
  const organizationId = getOrganizationId(getState())

  return dispatch(
    apiCall({
      method: 'DELETE',
      endpoint: `/admin/organizations_icons/${id}`,
      types: DELETE_ORGANIZATION_ICON,
      payload: {
        organizationId,
        iconId: id,
        deletedId: id,
      },
    }),
  )
}
