import { createAsyncAction } from 'Store/utils'

import apiCall from 'Services/Api'
import {
  getCheckedActiveStickers,
  getCheckedDeactivatedStickers,
  getStickersKind,
} from 'Store/Selectors/admin/stickers'

export const SET_STICKERS_KIND = 'admin/stickers/SET_STICKERS_KIND'
export const SET_STICKERS_PAGE = 'admin/stickers/SET_STICKERS_PAGE'
export const SET_STICKERS_SIZE = 'admin/stickers/SET_STICKERS_SIZE'
export const CHECK_STICKER = 'admin/stickers/CHECK_STICKER'
export const LOAD_STICKERS = createAsyncAction('admin/stickers/LOAD_STICKERS')
export const CREATE_STICKER = createAsyncAction('admin/stickers/CREATE_STICKER')
export const DEACTIVATE_STICKERS = createAsyncAction(
  'admin/stickers/DEACTIVATE_STICKERS',
)
export const ACTIVATE_STICKERS = createAsyncAction(
  'admin/stickers/ACTIVATE_STICKERS',
)

const stickersIncludes = ['postcard']

export const setStickersKind = (kind) => ({
  type: SET_STICKERS_KIND,
  payload: kind,
})

export const setStickersPage = (page) => ({
  type: SET_STICKERS_PAGE,
  payload: page,
})

export const setStickersSize = (size) => ({
  type: SET_STICKERS_SIZE,
  payload: size,
})

export const checkSticker = ({ id, state }) => ({
  type: CHECK_STICKER,
  payload: { id, isActive: state === 'active' },
})

export const loadStickers = ({ number = 1, size = 10 } = {}) => (
  dispatch,
  getState,
) => {
  const waitingRoomKind = getStickersKind(getState())

  return dispatch(
    apiCall({
      endpoint: '/admin/stickers/',
      types: LOAD_STICKERS,
      query: {
        include: stickersIncludes.join(),
        page: {
          number,
          size,
        },
        filter: {
          waitingRoomKind: {
            eq: waitingRoomKind,
          },
        },
      },
      paged: true,
      payload: {
        paged: {
          number,
          size,
        },
      },
    }),
  )
}

export const createSticker = ({ postcardId }) => (dispatch, getState) => {
  const waitingRoomKind = getStickersKind(getState())

  return dispatch(
    apiCall({
      endpoint: '/admin/stickers',
      method: 'POST',
      types: CREATE_STICKER,
      query: {
        include: stickersIncludes.join(),
        data: {
          type: 'stickers',
          attributes: {
            waitingRoomKind,
          },
          relationships: {
            postcard: {
              data: {
                type: 'postcards',
                id: postcardId,
              },
            },
          },
        },
      },
    }),
  )
}

export const deactivateStickers = () => (dispatch, getState) => {
  const checked = getCheckedActiveStickers(getState())

  return dispatch(
    apiCall({
      endpoint: '/admin/stickers/deactivation',
      method: 'POST',
      types: DEACTIVATE_STICKERS,
      query: {
        data: checked.map((item) => ({
          id: item.toString(),
          type: 'stickers',
        })),
      },
    }),
  )
}

export const activateStickers = () => (dispatch, getState) => {
  const checked = getCheckedDeactivatedStickers(getState())

  return dispatch(
    apiCall({
      endpoint: '/admin/stickers/activation',
      method: 'POST',
      types: ACTIVATE_STICKERS,
      query: {
        data: checked.map((item) => ({
          id: item.toString(),
          type: 'stickers',
        })),
      },
    }),
  )
}
